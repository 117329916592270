<template>
  <b-row>
    <b-col
      md="12"
      class="d-flex justify-content-between"
    >
      <div class="pb-1">
        <template v-if="!classRoomId">
          <b-button
            variant="flat-secondary"
            class="btn-icon"
            @click="classRoomId ? $emit('back') : viewClass"
          >
            <span v-if="classRoomInfo"> &#8701; {{ classRoomInfo.name }}</span>
          </b-button>
          |

          <b-button
            variant="flat-secondary"
            class="btn-icon"
            @click="$router.push({ name: self.usertype +'-classes' })"
          >
            {{ $t('student-lab-module.classroom-list') }}
          </b-button>
        </template>
      </div>
      <div>
        <b-button
          v-if="isATeacher"
          variant="success"
          class="btn-icon"
          @click="$emit('showClassManagementSide', 'calendar-event')"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t('labels.create-test') }}
        </b-button>
      </div>
    </b-col>
    <template v-if="classTestLists.length>0">
      <b-col cols="5">
        <test-list
          :class-test-lists="classTestLists"
          :selected-test="selectedTest"
          @selectGroup="(value) => selectedTest = value"
        />
      </b-col>
      <b-col cols="7">
        <lesson-lists
          :selected-lesson-lists="selectedLessonLists"
          :selected-test="selectedTest"
          :room-id="computedClassRoomId"
          :can-start-lesson="canStartLesson"
          @openTestLesson="handleShowLesson"
        />
      </b-col>
    </template>
    <template v-else>
      <b-col>
        <b-card
          class="mb-0 p-2"
          no-body
        >
          <h4 class="font-weight-bold justify-content-between">
            {{ $t('class-module.no-classes') }}
          </h4>
        </b-card>
      </b-col>
    </template>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import useApollo from '@/@core/graphql/useApollo'
import { USER_TYPE_TEACHER } from '@/const/userType'
import TestList from './components/TestList.vue'
import LessonLists from './components/LessonLists.vue'

export default ({
  components: {
    BRow, BCol, BButton, TestList, LessonLists, BCard,
  },
  props: {
    classRoomId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      classId: null,
      self: getUserData(),
      classRoomInfo: null,
      classTestLists: [],
      selectedTest: {},
    }
  },
  computed: {
    selectedLessonLists() {
      return this.selectedTest?.lessons?.map(data => ({
        ...data,
        startedAt: data.loader?.created_at,
        completedAt: data.loader?.completed_at,
        status: data.loader?.status,
        user_id: data.loader?.user_id,
        round: data.loader?.round,
        totalItem: data.loader?.total_items,
        totalPass: data.loader?.total_pass || 0,
      })) || []
    },
    canStartLesson() {
      return (this.selectedTest.event && this.DAYS_DIFF_FROM_NOW(this.selectedTest.event.start_time) >= 0) || !!this.selectedTest.event.is_non_sequence || this.isATeacher
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    computedClassRoomId() {
      return this.classRoomId ? this.classRoomId : this.$route.params.classId
    },
  },
  watch: {
    '$route.params.classId': {
      handler(id) {
        this.classId = id
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    const classId = this.computedClassRoomId
    this.getLessonsForCourse(classId)
    this.getClassRoomInfo()
  },
  methods: {
    getClassTestTimeMessage() {
      return `Test will be unlocked in ${this.FROM_NOW(this.selectedTest.event.start_time)}`
    },
    getClassRoomInfo() {
      useApollo.getClassRoomInfo(this.computedClassRoomId).then(response => {
        this.classRoomInfo = response.data.room
      })
    },
    getLessonsForCourse(classId) {
      useJwt.getSelectedLessonsForTest(classId).then(response => {
        this.classTestLists = response.data?.data
        this.selectedTest = response.data?.data[0] || {}
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    viewClass() {
      this.$router.push({
        name: `${this.self.usertype}-view-students`,
        params: { id: this.computedClassRoomId },
      })
    },
    handleShowLesson(lesson, testClassEventId) {
      this.$emit('openTestLesson', lesson, testClassEventId)
    },
  },
})
</script>
