<template>
  <b-card
    id="report-card"
    no-body
    class="mb-0 p-0"
  >
    <b-overlay
      variant="white"
      :show="isProcessing"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-row>
        <b-col
          cols="12"
          class="text-right p-1"
        >
          <b-button
            class="mx-1"
            variant="success"
            data-html2canvas-ignore="true"
            :disabled="isPdfProcessing"
            @click="exportPdf()"
          >
            <b-spinner
              v-if="isPdfProcessing"
              small
            />
            {{ $t('report.export-pdf') }}
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="pt-2 pl-2 pr-2">
        <b-col
          v-for="(summary, index) of getSummary"
          :key="index"
          class="d-flex align-items-stretch col"
          md="4"
        >
          <b-card
            class="w-100"
            :class="summary.colorClass"
          >
            <h4> <feather-icon
              :icon="summary.icon"
              size="20"
            /> {{ summary.title }}</h4>
            <div
              v-for="detail of summary.data"
              :key="detail.title"
              class="d-flex justify-content-between"
            >
              <div>{{ detail.title }}</div>
              <div><b>{{ detail.value }}</b></div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        class="p-1"
      >
        <b-col
          md="7"
          sm="12"
        >
          <b-row>
            <b-col
              md="12"
            >
              <b-row
                class="d-flex align-items-center justify-content-between"
                data-html2canvas-ignore="true"
              >
                <b-col md="4">
                  <h5 class="p-1 pb-0 d-inline-block">
                    {{ $t("student-report-module.list-of-problems") }}
                  </h5>
                  <span>
                    <b-badge
                      pill
                      variant="primary"
                    >{{
                      problem.total_problems_attempted
                    }}</b-badge>
                  </span>
                </b-col>

                <b-col
                  md="5"
                  style="max-width: 400px"
                >
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    class="mr-1"
                    :placeholder="$t('actions.type-to-search')"
                    @dragover.prevent
                    @dragenter.prevent
                  />
                </b-col>

              </b-row>
              <b-row data-html2canvas-ignore="true">
                <b-col
                  md="12"
                  class="text-right pb-1"
                >
                  <b-button
                    variant="primary"
                    @click="exportExcel()"
                  >
                    {{ $t('course-completion-module.excel-export') }}
                    <feather-icon icon="FileTextIcon" />
                  </b-button>

                </b-col>
              </b-row>
            </b-col>

            <b-col
              md="12"
            >
              <b-table
                class="report-table"
                responsive
                :items="getProblemsForTable"
                :fields="columns"
                show-empty
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                hover
                small
                @filtered="onFiltered"
              >
                <template #empty="">
                  <div class="w-100 d-flex justify-content-center align-items-centert">
                    <p class="p-0 m-0">
                      {{ $t('messages.no-record-found') }}
                    </p>
                  </div>
                </template>
                <template #emptyfiltered="">
                  <div class="w-100 d-flex justify-content-center align-items-centert">
                    <p class="p-0 m-0">
                      {{ $t('messages.no-record-found') }}
                    </p>
                  </div>
                </template>
                <template #cell(is_correct)="data">
                  <div v-if="data.item.attempt_data.length > 0">
                    <span
                      v-for="(attempt, index) in data.item.attempt_data"
                      :key="index"
                      @click="() => $emit('showPreview', { ...data.item, test_id: attempt.test_id, lang_level: attempt.language_level })"
                    >
                      <feather-icon
                        v-if="attempt.right && !attempt.wrong"
                        v-b-tooltip.hover.top="data.item.correctResult[index]"
                        icon="CheckIcon"
                        size="15"
                        class="text-success"
                      />
                      <feather-icon
                        v-else
                        v-b-tooltip.hover.top="data.item.correctResult[index]"
                        size="15"
                        icon="XIcon"
                        class="text-danger"
                      />
                    </span>
                  </div>
                  <span
                    v-else
                    @click="() => $emit('showPreview', data.item)"
                  >
                    <feather-icon
                      v-if="data.item.is_correct"
                      v-b-tooltip.hover.top="data.item.correct"
                      icon="CheckIcon"
                      size="15"
                      class="text-success"
                    />
                    <feather-icon
                      v-else
                      v-b-tooltip.hover.top="data.item.correct"
                      icon="XIcon"
                      size="15"
                      class="text-danger"
                    />
                  </span>
                </template>
                <template #cell(correct)="data">
                  <span
                    v-if="data.item.problem_type !== 'open-end-writing' || data.item.hasTeacherScore"
                    :class="{
                      'text-primary' : data.item.hasTeacherScore
                    }"
                  >
                    {{ data.item.correct }}
                  </span>
                  <span v-else>
                    Checking
                  </span>
                </template>
                <template #cell(lang_level)="data">
                  <div v-if="data.item.attempt_data.length > 0 && !data.item.hasTeacherScore">
                    <span
                      v-for="(attempt, index) in data.item.attempt_data"
                      :key="index"
                    >
                      <span
                        class="badge text-white"
                        :style="`background:${getColorForLevel(attempt.lang_level)}`"
                        style="padding:4px;margin-right:2px"
                        @click="() => $emit('showPreview', { ...data.item, test_id: attempt.test_id, lang_level: attempt.language_level })"
                      >
                        {{ getLangLevelTransText(getLevelFromIndex(attempt.lang_level)) }}
                      </span>
                    </span>
                  </div>

                  <span
                    v-else
                    class="badge text-white"
                    :style="`background:${getColorForLevel(data.item.lang_level)}`"
                    style="padding:4px;margin-right:2px"
                    @click="() => $emit('showPreview', data.item)"
                  >
                    {{ getLangLevelTransText(data.item.lang_level) }}
                  </span>
                </template>
                <template #cell(action)="data">
                  <b-button
                    :id="`stat-report-${index}`"
                    v-b-tooltip.hover.top="`View Input`"
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click="() => $emit('showPreview', data.item)"
                  >
                    <feather-icon icon="EyeIcon" /> {{ data.item.id }}
                  </b-button>
                </template>
                <template
                  slot="custom-foot"
                  slot-scope=""
                >
                  <tr class="level-indicator">
                    <th>{{ $t("student-report-module.avg-level") }}</th>
                    <th>
                      <level-indicator
                        v-if="problem.cefr_rate"
                        indicator-color="#d35400"
                        :level="problem.cefr_rate"
                        type="percentage"
                      />
                    </th>
                    <th />
                    <th />
                    <th />
                    <th>{{ $t("student-report-module.problem-solving-time") }}</th>
                    <th>{{ problem.total_time }}</th>
                    <th />
                  </tr>
                </template>
              </b-table>
              <b-row>
                <b-col
                  md="12"
                >
                  <h6 class="text-warning font-italic">
                    {{ $t('view-student-input') }}
                    <feather-icon
                      icon="CheckIcon"
                      size="18"
                      class="text-success"
                    />
                    <feather-icon
                      icon="XIcon"
                      size="18"
                      class="text-danger"
                    />
                  </h6>
                </b-col>
                <b-col
                  v-if="totalRows > perPage"
                  cols="12 d-flex justify-content-end"
                  data-html2canvas-ignore="true"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    :disabled="isProcessing"
                    align="center"
                    style="margin:3px 0"
                    @change="currentPage = $event"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <MeasuringScale v-if="problem.cefr_rate"
                                  :score="problem.cefr_rate"
                  />
                </b-col>
              </b-row>

              <p
                v-if="!isProcessing && getProblemsForTable.length === 0"
                class="px-2"
              >
                {{ $t("student-report-module.no-data-available") }}
              </p>
            </b-col>

            <b-col
              v-if="problem.difficult_stacks && problem.difficult_stacks.difficult_stack.length > 0"
              md="6"
              sm="12"
            >
              <p>
                <strong>{{
                  $t("student-report-module.student-struggled-but-passed")
                }}</strong>
              </p>
              <b-table
                responsive
                :items="getDifficultProblems"
                :fields="columnsForNeedsHelpTable"
                hover
                small
                @row-clicked="(d) => $emit('showPreview', d)"
              />
            </b-col>
            <b-col
              v-if="problem.difficult_stacks && problem.difficult_stacks.help_stack.length > 0"
              md="6"
              sm="12"
            >
              <p>
                <strong>{{
                  $t("student-report-module.student-struggled-and-did-not-pass")
                }}</strong>
              </p>
              <b-table
                responsive
                :items="getNeedsHelpProblems"
                :fields="columnsForNeedsHelpTable"
                hover
                small
                @row-clicked="(d) => $emit('showPreview', d)"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col
          md="5"
          sm="12"
          style="margin-top: 4px"
        >
          <div>
            <div class="student-chart">
              <skill-summary-chart
                v-if="getChartData"
                style="padding-left: 10px"
                :series="getChartData.series"
                :categories="getChartData.category"
              />
            </div>

            <div
              class="justify-content student-chart"
            >
              <h5>Study Pattern Of Student</h5>
              <div id="chart">
                <VueApexCharts type="pie"
                               :options="chartOptions"
                               :series="getStudyPatternPieChartData"
                />
              </div>
            </div>
          </div>
          <div v-if="problem.standards && problem.standards.length>0">
            <p class="mt-2">
              <strong>{{ $t("student-report-module.standards") }}</strong>
            </p>
            <b-table
              responsive
              :items="getStandards"
              :fields="columnsForStandardsTable"
              small
              hover
            >
              <template #cell(status)="data">
                <feather-icon
                  v-if="data.item.status===1"
                  icon="CheckIcon"
                  size="15"
                  class="text-success"
                />
                <feather-icon
                  v-else
                  size="15"
                  icon="XIcon"
                  class="text-danger"
                />
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BCol,
  BRow,
  BFormInput,
  BPagination,
  BBadge,
  BOverlay,
  BSpinner,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'
import LevelIndicator from '@/views/common/components/LevelIndicator.vue'
import { getColorForLevel, getLevelFromIndex } from '@/const/langLevel'
import SkillSummaryChart from '@/views/common/Charts/SkillSummaryChart.vue'
import exportFromJSON from 'export-from-json'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import MeasuringScale from './MeasuringScale.vue'

export default {
  components: {
    BCard,
    BBadge,
    BTable,
    BCol,
    BRow,
    BOverlay,
    BFormInput,
    LevelIndicator,
    BPagination,
    SkillSummaryChart,
    BButton,
    BSpinner,
    VueApexCharts,
    MeasuringScale,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    problem: {
      type: Object,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    dialogName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filter: null,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      difficultProblems: [],
      needHelpProblems: [],
      isPdfProcessing: false,
      columnsForNeedsHelpTable: [
        {
          key: 'name',
          label: i18n.tc('student-report-module.problem-table.problem'),
        },
        {
          key: 'attempts',
          label: i18n.tc('student-report-module.problem-table.attempts'),
        },
      ],
      columnsForStandardsTable: [
        {
          key: 'name',
          label: i18n.tc('student-report-module.standards-table.name'),
        },
        {
          key: 'code',
          label: i18n.tc('student-report-module.standards-table.code'),
        },
        {
          key: 'status',
          label: i18n.tc('student-report-module.standards-table.status'),
        },
      ],
      columns: [
        {
          key: 'name',
          label: i18n.tc('student-report-module.problem-table.problem'),
        },
        {
          key: 'lang_level',
          label: i18n.tc('student-report-module.problem-table.lang-level'),
        },
        {
          key: 'problem_type',
          label: i18n.tc('student-report-module.problem-table.problem_type'),
        },
        {
          key: 'skill_type',
          label: i18n.tc('student-report-module.problem-table.skill-type'),
        },
        {
          key: 'correct',
          label: i18n.tc('student-report-module.problem-table.correct'),
        },
        {
          key: 'is_correct',
          label: i18n.tc('student-report-module.problem-table.status'),
        },
        {
          key: 'time_spent',
          label: i18n.tc('student-report-module.problem-table.time-spent'),
        },
        {
          key: 'action',
          label: i18n.tc('course-completion-module.table-header.actions'),
        },
        // { key: 'student_answer', label: 'Student Answer' },
      ],
      chartOptions: {
        chart: {
          width: 480,
          type: 'pie',
        },
        labels: ['Preparation', 'Problem Solving', 'Reading Feedback'],
      },

    }
  },

  computed: {
    getProblemsForTable() {
      return this.problem.tests
        ? this.problem.tests.map(e => {
          const correctResult = []
          const attemptData = e.attemptedData.length > 0 ? e.attemptedData : [
            {
              wrong: e.incorrect,
              right: e.correct,
            },
          ]
          attemptData.forEach(attempt => {
            const totalProblem = Number(attempt.right) + Number(attempt.wrong)
            const correct = Math.round((attempt.right / totalProblem) * 100, 2)
            if (totalProblem > 0) {
              correctResult.push(`${correct} %`)
            } else {
              // TODO:: investigate NAN
              correctResult.push('-')
            }
          })
          const hasTeacherScore = e.score != null
          return {
            name: e.problem,
            lang_level: e.language_level,
            time_spent: `${e.time}`,
            is_correct: e.incorrect === 0,
            attempt_data: e.attemptedData,
            attempt: e.attempt,
            problem_type: e.problem_type,
            skill_type: e.skill_type,
            problem_id: e.problem_id,
            test_id: e.test_id,
            hasTeacherScore,
            correct: hasTeacherScore ? `${e.score.toFixed(2)} %` : correctResult[correctResult.length - 1],
            correctResult,
          }
        })
        : []
    },
    getNeedsHelpProblems() {
      return this.problem.difficult_stacks.help_stack.map(e => ({
        name: e.name,
        attempts: e.attempted,
        problem_id: e.problem_id,
        lang_level: e.lang_level,
        test_id: e.test_id,
      }))
    },
    getStandards() {
      if (!this.problem.standards) return []
      return this.problem.standards.map(e => ({
        name: e.name,
        code: e.code,
        status: e.status,
      }))
    },
    getDifficultProblems() {
      return this.problem.difficult_stacks.difficult_stack.map(e => ({
        name: e.name,
        attempts: e.attempted,
        problem_id: e.problem_id,
        lang_level: e.lang_level,
        test_id: e.test_id,
      }))
    },
    getSummary() {
      return [
        {
          title: this.$i18n.tc('lesson-summary'),
          colorClass: 'bg-light-warning',
          icon: 'BookOpenIcon',
          data:
          [
            { title: i18n.tc('student-report-module.summary-table.lesson-type'), value: this.problem.lesson_type },
            { title: i18n.tc('student-report-module.summary-table.total-problems-in-lesson'), value: this.problem.total_problems_in_lesson },
            { title: i18n.tc('student-report-module.summary-table.required-percentage'), value: `${Number(this.problem.required_percentage)}%` },
            { title: i18n.tc('student-report-module.summary-table.total-standards'), value: this.getStandards.length },
          ],

        },
        {
          title: this.$i18n.tc('score-summary'),
          colorClass: 'bg-light-success',
          icon: 'AwardIcon',
          data: [
            { title: i18n.tc('student-report-module.summary-table.total-problems-attempted'), value: this.problem.total_problems_attempted },
            { title: i18n.tc('student-report-module.summary-table.total-problems-passed'), value: this.problem.total_passed },
            { title: i18n.tc('student-report-module.summary-table.total-problems-failed'), value: this.problem.total_failed },
            { title: i18n.tc('student-report-module.summary-table.total-pass-percentage'), value: `${this.problem.pass_percent > 100 ? 100 : this.problem.pass_percent}%` },
            ...(this.problem?.scoreAverage ? [
              { title: i18n.tc('student-report-module.summary-table.performance-score'), value: `${this.number_format(this.problem?.scoreAverage, 2)}%` },
            ] : []),
          ],
        },
        {
          title: this.$i18n.tc('time-summary'),
          colorClass: 'bg-light-primary',
          icon: 'ClockIcon',
          data: [
            // { title: i18n.tc('student-report-module.summary-table.duration'), value: this.problem.total_duration_in_lesson && this.problem.total_duration_in_lesson.duration },
            { title: i18n.tc('student-report-module.summary-table.started_at'), value: this.problem.total_duration_in_lesson && moment(this.problem.total_duration_in_lesson.from).format('YYYY-MM-DD') },
            { title: i18n.tc('student-report-module.summary-table.completed_at'), value: this.problem.total_duration_in_lesson && moment(this.problem.total_duration_in_lesson.to).format('YYYY-MM-DD') },
            { title: i18n.tc('student-report-module.summary-table.total_preparation_time'), value: this.problem.time_summary && this.convertSecondsToTime(this.problem.time_summary.total_statement_time) },
            { title: i18n.tc('student-report-module.summary-table.total_solving_time'), value: this.problem.time_summary && this.convertSecondsToTime(this.problem.time_summary.total_body_time) },
            { title: i18n.tc('student-report-module.summary-table.total_feedback_time'), value: this.problem.time_summary && this.convertSecondsToTime(this.problem.time_summary.total_feedback_time) },
          ],
        },

      ]
    },
    getChartData() {
      if (!this.problem.chart) {
        return null
      }
      const dataWithScores = this.problem.chart.map(score => ({
        ...score,
        percent: Math.round((score.pass / score.total) * 100, 2),
      }))
      dataWithScores.sort((a, b) => a.percent - b.percent)

      const category = dataWithScores.map(chart => this.capitalizeFirstLetter(chart.name))
      const series = [
        {
          name: 'Score',
          data: dataWithScores.map(chart => chart.percent),
        },
      ]
      return {
        category,
        series,
      }
    },

    getStudyPatternPieChartData() {
      if (!this.problem.time_summary) {
        return []
      }
      return Object.values(this.problem.time_summary)
    },

  },

  watch: {
    problem() {
      this.calculateRequiredData()
    },
  },

  mounted() {
    this.calculateRequiredData()
  },

  methods: {
    getLevelFromIndex(lv) {
      return getLevelFromIndex(lv)
    },
    getColorForLevel(lv) {
      return getColorForLevel(lv)
    },
    calculateRequiredData() {
      this.totalRows = this.problem.tests ? this.problem.tests.length : 0
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    exportExcel() {
      const data = this.getProblemsForTable.map(report => ({
        Problem: report.name,
        'Lang Level': this.getLangLevelTransText(report.lang_level),
        'Problem Type': report.problem_type,
        'Skill Type': report.skill_type,
        Correct: report.correct,
        Time: report.time_spent,
      }))
      const fileName = `${this.dialogName}`
      const exportType = 'csv'

      exportFromJSON({ data, fileName, exportType })
    },
    exportPdf() {
      this.perPage = 100
      this.isPdfProcessing = true
      setTimeout(() => {
        const element = document.getElementById('report-card')
        this.printPdf(element, this.dialogName)
        this.perPage = 5
        this.isPdfProcessing = false
      }, 2000)
    },
    convertSecondsToTime(seconds) {
      const duration = moment.duration(seconds, 'seconds')
      return moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
    },
  },
}
</script>

<style scoped>
.class-table .active-column {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.dialog-drag.student-problems-report {
  width: 1200px;
}
.class-table tr {
  cursor: pointer;
}
.class-table {
  margin-bottom: 0;
}
.report-table tfoot {
  height: 48px;
}
.report-table tfoot th {
  padding-top: 12px !important;
}
table{
  table-layout: fixed !important;
   width:100%;
}

th,td{
  overflow :hidden !important;

}
tr,td,th{
   page-break-inside: avoid;
}

.student-chart{
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}
.apexcharts-legend {
  margin-top: 20px;
}
</style>
