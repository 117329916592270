<template>
  <b-row>
    <b-col
      v-if="!data.result && !teachersStudentId && !processing"
      class="pt-3"
    >
      <FailedSympathy
        v-if="resultInfo.is_answer_prepared && resultInfo.status === 'failed'"
        :processing="processing"
        :self="self"
        :lesson="resultInfo.lesson"
        :result-info="resultInfo"
        @viewResult="getResult"
      />
      <congratulation
        v-else-if="resultInfo.is_answer_prepared"
        :processing="processing"
        :self="self"
        :lesson="resultInfo.lesson"
        @viewResult="getResult"
      />
      <wait-for-answer v-else
                       :processing="processing"
                       :self="self"
                       :lesson="resultInfo.lesson"
                       :result-info="resultInfo"
                       @viewResult="getResult"
      />
    </b-col>
    <b-col
      v-else-if="data.result"
      md="12"
      class="p-0"
    >
      <b-card
        v-if="data"
        text-variant="center"
        class="card not-student-card h-100 rounded-0"
      >
        <b-col
          v-if="!isFrameView"
          md="12"
          class="p-0 d-flex justify-content-start flex-wrap pb-1"
        >
          <div>
            <b-button
              variant="flat-secondary"
              class="btn-icon"
              @click="GO_BACK()"
            >
              <feather-icon
                size="20"
                icon="ArrowLeftIcon"
              />
            </b-button>
            <b-button
              variant="flat-secondary"
              class="btn-icon"
              @click="$router.push({name: `${self.usertype}-view-students`,params: { id: classId },})"
            >
              <span>
                {{ $t('labels.classroom') }}
              </span>
            </b-button>
            |
            <b-button
              variant="flat-secondary"
              class="btn-icon"
              @click="() => {
                if (classTestId) {
                  return $router.push({name:`${self.usertype}-class-test`,params:{classId} })
                }
                $router.push({
                  name: `${self.usertype}-view-students`,
                  params: { id: classId },
                  query: { 'view-lesson': data.course },
                })
              }
              "
            >
              {{ $t("actions.list-of-lessons") }}
            </b-button>
          </div>

        </b-col>
        <!-- message -->
        <b-col>
          <b-row>
            <b-col
              v-if="data.image"
              :md="data.image ? '2' : '0'"
              :sm="data.image ? '2' : '0'"
              offset="3"
            >
              <b-img
                :src="data.image"
                style="max-height: 200px"
                thumbnail
                fluid
              />
            </b-col>
            <b-col
              :md="data.image ? '6' : '12'"
              sm="12"
              class="mt-2 sm-mt-0 d-flex flex-column justify-content-end pl-0"
            >
              <h1 class="mb-1 mt-50">
                {{ $t("student-test-module.lesson-completed") }}
              </h1>
              <b-card-text>
                <h5>
                  <span v-if="teachersStudentId">
                    <strong> {{ data.userName }}</strong>
                    {{
                      $t("student-test-module.completed-all-standards-student")
                    }}
                  </span>
                  <span
                    v-else
                  >{{ $t("student-test-module.completed-all-standards") }}
                  </span>
                  <strong> {{ data.lesson }}</strong>
                </h5>
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>
        <!-- message -->

        <!-- stats -->
        <div
          v-if="!isResultDDF"
          class="d-flex justify-content-around flex-wrap mt-4"
        >
          <div class="text-center">
            <b-card-text>
              {{ $t("student-test-module.required-percentage") }}
            </b-card-text>
            <span
              class="font-large-1 font-weight-bold"
            >{{ data.required_percentage || 40 }}%</span>
          </div>
          <div class="text-center">
            <b-card-text>
              {{ $t("student-test-module.obtained-percentage") }}
            </b-card-text>
            <span
              class="font-large-1 font-weight-bold"
              :class="{
                'text-danger': hasFailed,
                'text-success': !hasFailed,
              }"
            >{{ data.score_percentage }}%</span>
          </div>
          <div class="text-center">
            <b-card-text>
              {{ $t("student-test-module.obtain-point") }}
            </b-card-text>
            <span class="font-large-1 font-weight-bold">{{
              data.obtained_points
            }}</span>
          </div>
          <div class="text-center">
            <b-card-text>
              {{ $t("student-test-module.taken-time") }}
            </b-card-text>
            <span
              v-if="data.total_time_spent"
              class="font-large-1 font-weight-bold"
            >
              {{ data.total_time_spent }}
            </span>
            <span
              v-else
              class="font-large-1 font-weight-bold"
            > N/A </span>
          </div>
        </div>
        <!-- stats -->
        <div v-if="viewDetailTable"
             class="mt-2"
        >
          <b-table
            v-if="showReportDetails"
            responsive="sm"
            class="table-hover"
            :items="getResultRows"
            :fields="fields"
            borderless
          >
            <template #cell(status)="d">
              <span>
                <feather-icon
                  v-if="d.item.status === 'passed' || isResultDDF"
                  size="20"
                  icon="CheckIcon"
                  class="text-success"
                />
                <feather-icon
                  v-else
                  size="20"
                  icon="XIcon"
                  class="text-danger"
                />
              </span>
            </template>
            <template #cell(passed_problems)="d">
              <span
                v-if="isResultDDF"
                class="text-success"
              >
                <b-badge variant="success">
                  {{ d.item.passed_problems }}
                </b-badge>
                <b-badge variant="danger">
                  {{ d.item.failed_problem }}
                </b-badge>
              </span>
              <span
                v-else
                :class="{
                  'text-danger': d.item.status != 'passed',
                  'text-success': d.item.status == 'passed',
                }"
              >
                <b-badge variant="success"
                         class="mr-1"
                >
                  {{ d.item.passed_problems }}
                </b-badge>
                <b-badge variant="danger">
                  {{ d.item.failed_problem }}
                </b-badge>
              </span>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col class="d-flex justify-content-end">
            <!-- <b-button
              variant="outline-primary"
              class="d-flex align-items-center"
              @click="
                $router.replace({
                  name: `${self.usertype}-lessons`,
                  params: { id: data.course },
                  query: { group: data.gid },
                })
              "
            >
              {{ $t("actions.to-class-list") }}
            </b-button> -->
            <!-- <b-button
              v-if="hasFailed"
              variant="outline-primary"
              class="d-flex align-items-center"
              @click="$emit('onRetakeExam')"
            >
              {{ $t('student-test-module.try-again') }}
            </b-button> -->
            <b-button
              v-if="!viewDetailTable"
              v-b-modal.result-details-modal
              variant="outline-primary"
              class="mt-1"
            >
              {{ $t('View Report Details') }}
            </b-button>
            <b-button
              v-if="data.next_lesson != null && data.next_lesson !== 0 && viewDetailTable"
              variant="outline-primary"
              class="d-flex align-items-center"
              @click="() => {
                if (classTestId) {
                  return $router.push({name:`${self.usertype}-class-test`,params:{classId} })
                }
                $router.push({
                  name: `${self.usertype}-view-students`,
                  params: { id: classId },
                  query: { 'view-lesson': data.course },
                })
              }
              "
            >
              {{ $t("student-test-module.next-lesson") }}
            </b-button>
            <b-button
              v-else-if="data.is_course_completed"
              variant="outline-primary"
              class="d-flex align-items-center"
              @click="
                () => {
                  if (classTestId) {
                    return $router.push({name:`${self.usertype}-class-test`,params:{classId} })
                  }
                  $router.push({
                    name: `${self.usertype}-course-result`,
                    params: { id: data.course },
                  })
                }
              "
            >
              {{ $t("student-test-module.course-result") }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col v-else>
      <b-skeleton
        animation="wave"
        width="85%"
      />
      <b-skeleton
        animation="wave"
        width="55%"
      />
      <b-skeleton
        animation="wave"
        width="70%"
      />
    </b-col>

    <ResultDetailsModal
      v-if="!viewDetailTable"
      :loader-id="routeParams.loaderId"
    />
  </b-row>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BTable,
  BImg,
  BSkeleton,
  BBadge,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
// import useApollo from '@/@core/graphql/useApollo'
// import { STATUS_IDLE } from '@/const/avatar'
import i18n from '@/libs/i18n'
import Congratulation from './Congralution.vue'
import WaitForAnswer from './WaitForAnswer.vue'
import FailedSympathy from './FailedSympathy.vue'
import ResultDetailsModal from './ResultDetailsModal.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BTable,
    BButton,
    BBadge,
    BImg,
    Congratulation,
    BSkeleton,
    WaitForAnswer,
    FailedSympathy,
    ResultDetailsModal,
  },
  props: {
    viewType: {
      type: String,
      default: '',
    },
    teachersStudentId: {
      type: [Object, Number],
      default: () => null,
    },
    resultInfo: {
      type: Object,
      default: () => {},
    },
    routeParams: {
      type: Object,
      default: () => null,
    },
    viewDetailTable: {
      type: Boolean,
      default: true,
    },
    showReportDetails: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    const routeParams = !this.routeParams ? {
      classId: this.$route.params.classId,
      lessonId: this.$route.params.id,
      eventId: this.$route.query.event_id,
    } : this.routeParams
    return {
      self: getUserData(),
      data: {},
      classId: Number(routeParams.classId),
      lessonId: Number(routeParams.id),
      eventId: Number(routeParams.event_id),
      processing: false,
      isResultDDF: false,
      fields: [
        { key: 'code', label: i18n.tc('points-setting-module.code') },
        { key: 'standard', label: i18n.tc('standard') },
        { key: 'passed_problems', label: i18n.tc('passed_problems') },
        { key: 'status', label: i18n.tc('issue-report-module.status') },
      ],
    }
  },
  computed: {
    isFrameView() {
      return this.viewType === 'modal'
    },
    hasFailed() {
      return !this.data.hasPassed
    },
    getResultRows() {
      return this.data.result.map(e => ({
        code: e.code,
        standard: e.standard,
        passed_problems: `${e.result?.pass || 0}`,
        failed_problem: `${e.result?.fail || 0}`,
        status: !e.result?.fail
          ? 'passed'
          : 'failed',
      }))
    },
    classTestId() {
      return this.$route.query.testId || this.routeParams?.testId
    },
  },
  watch: {
    hasFailed: {
      handler(bool) {
        if (bool && this.data.result) {
          this.getResult()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    localStorage.removeItem('previousTimestamp')
    this.getResult() // directly show result when student id is passed
  },
  methods: {
    getResult() {
      //  score_percentage is comming in point
      this.data = {
        result: this.resultInfo.result,
        required_percentage: this.resultInfo.required_percentage,
        score_percentage: this.resultInfo.score_percentage,
        image: this.resultInfo.image,
        lesson: this.resultInfo.lesson,
        obtained_points: this.resultInfo.score_points,
        time_spent: this.resultInfo.total_time_spent,
        course: this.resultInfo.course,
        is_course_completed: this.resultInfo.is_course_completed,
        next_lesson: this.resultInfo.next_lesson,
        hasPassed: this.resultInfo.status === 'passed' || (this.resultInfo.score_percentage >= this.resultInfo.required_percentage),
        userName: this.resultInfo.user,
        total_time_spent: this.resultInfo.total_time_spent,
      }
    },
  },
}
</script>

<style>
  .dark-layout .table-hover tbody tr:hover {
    background-color: #384361;
  }
</style>
